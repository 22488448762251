<template>
  <div
    :class="containerClasses"
  >
    <avatar :user-data="response.participant" />
    <div class="message-format-right">
      <div class="message-format-header">
        <div class="message-format-name">
          {{ userFullName }}
        </div>
        <div class="message-format-time">
          {{ formattedResponseDate }}
        </div>
      </div>
      <div class="response">
        <div
          v-if="isOpenQuestion"
          class="open-question message-format-content"
        >
          {{ response.value }}
          <div
            v-if="response.responseMediaFiles && response.responseMediaFiles.length > 0"
            :class="{ 'response-media-files': true, 'display-image-descriptions': displayDescriptions }"
          >
            <div
              v-for="(mediaFile) in response.responseMediaFiles"
              :key="mediaFile.identifier"
              class="response-media-file-container"
            >
              <media
                :model="mediaFile"
                class="response-media-file"
                :media-width="120"
              />
              <div
                v-if="displayDescriptions && mediaFile.description"
                class="response-media-file-description"
              >
                {{ mediaFile.description }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="isChoice"
          class="choice"
        >
          <task-option
            v-for="(optionIdentifier) in response.options"
            :key="optionIdentifier"
            :option="getOption(optionIdentifier)"
          />
          <task-option
            v-if="taskData.customOptionText && response.value"
            :option="{ description: `${taskData.customOptionText}: ${response.value}` }"
          />
        </div>
        <div
          v-else-if="isNPS"
          class="nps"
        >
          <task-optionNPS
            v-for="(optionIdentifier) in response.options"
            :key="optionIdentifier"
            :option="getOption(optionIdentifier)"
          />
        </div>
        <div
          v-else-if="isRating"
          class="rating"
        >
          <task-option-rating
            v-for="(optionValue) in response.optionValues"
            :key="optionValue.option"
            :option="getOption(optionValue.optionIdentifier)"
            :grade="optionValue.integerValue"
            :icon="taskData.icon"
          />
        </div>
        <div
          v-if="displayFooter"
          :class="footerClasses"
        >
          <tag-list
            v-if="computedDisplayParticipantTags"
            :tag-data="participantTags"
          />
          <blue-action-button
            v-if="displayComments"
            :link="{ name: 'response-interactions', params: responseInteractionsParams }"
          >
            {{ commentsCount }} {{ `${commentsCount === 1 ? 'comentario' : 'comentarios' }` }}
          </blue-action-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'timeago.js'

import Avatar from '@/components/Avatar.vue'
import BlueActionButton from '@/components/BlueActionButton.vue'
import Media from '@/components/Media.vue'
import TagList from '@/components/TagList.vue'
import TaskOption from '@/components/interactions/Option.vue'
import TaskOptionNPS from '@/components/interactions/OptionNPS.vue'
import TaskOptionRating from '@/components/interactions/OptionRating.vue'
import SharedConstants from '@/shared/constants.json'

export default {
  name: 'InteractionsResponse',
  components: {
    Avatar,
    BlueActionButton,
    Media,
    TagList,
    TaskOption,
    TaskOptionNPS,
    TaskOptionRating
  },
  props: {
    response: {
      type: Object,
      default: null
    },
    taskData: {
      type: Object,
      required: true
    },
    commentsCount: {
      type: Number,
      default: 0
    },
    displayParticipantTags: {
      type: Boolean,
      default: false
    },
    displayBorders: {
      type: Boolean,
      default: true
    },
    displayComments: {
      type: Boolean,
      default: false
    },
    displayResponseImagesDescription: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activityIdentifier() {
      return this.$route.params.activityIdentifier
    },
    projectIdentifier() {
      return this.$route.params.projectIdentifier
    },
    taskIdentifier() {
      return this.$route.params.taskIdentifier
    },
    responseInteractionsParams() {
      return {
        activityIdentifier: this.activityIdentifier,
        projectIdentifier: this.projectIdentifier,
        responseIdentifier: this.response.identifier,
        taskIdentifier: this.taskIdentifier
      }
    },
    taskType() {
      return this.taskData.taskType
    },
    isChoice() {
      return this.taskType === SharedConstants.TASK_TYPE.SIMPLE_CHOICE
        || this.taskType === SharedConstants.TASK_TYPE.MULTIPLE_CHOICE
    },
    isNPS() {
      return this.taskType === SharedConstants.TASK_TYPE.NPS
    },
    isOpenQuestion() {
      return this.taskType === SharedConstants.TASK_TYPE.OPEN_QUESTION
    },
    isRating() {
      return this.taskType === SharedConstants.TASK_TYPE.RATING
    },
    mediaResponse() {
      return this.taskType === SharedConstants.TASK_TYPE.OPEN_QUESTION
    },
    options() {
      return this.taskData.options
    },
    userColor() {
      const hash = this.hashCode(`${this.response.participant.firstName} ${this.response.participant.lastName}`)
      const color = this.intToRGB(hash)

      return color
    },
    userFontColor() {
      return `#${this.userColor}cc`
    },
    userBackgroundColor() {
      return `#${this.userColor}22`
    },
    userFullName() {
      return `${this.response.participant.firstName} ${this.response.participant.lastName}`
    },
    displayDescriptions() {
      return this.displayResponseImagesDescription
        && (
          this.response.responseMediaFiles.length > 0
          && this.response.responseMediaFiles.some((mf) => mf.description.trim())
        )
    },
    participantTags() {
      if (this.displayParticipantTags && !this.loading) {
        const participantData = this.$store.getters['staffParticipants/participantDataByUserIdentifier'](
          this.response.participant.identifier
        )

        if (participantData) return participantData.tags

        return null
      }

      return null
    },
    computedDisplayParticipantTags() {
      return this.displayParticipantTags && this.participantTags && this.participantTags.length > 0
    },
    displayFooter() {
      return this.computedDisplayParticipantTags || this.displayComments
    },
    footerClasses() {
      const classes = ['message-format-footer']

      if (this.isParticipant || (!this.participantTags || this.participantTags.length === 0)) {
        classes.push('align-right')
      }

      return classes
    },
    containerClasses() {
      const classes = ['response-container', 'message-format-container']

      if (this.displayBorders) classes.push('with-borders')

      return classes
    },
    formattedResponseDate() {
      return format(this.response.created, 'es')
    }
  },
  methods: {
    getOption(optionIdentifier) {
      return this.taskData.options.find((option) => option.identifier === optionIdentifier)
    }
  }
}
</script>

<style lang="scss" scoped>
.response-container {
  .response {
    .open-question {
      text-align: left;
      font-size: 14px;
      line-height: 17px;
      color: #979797;

      .response-media-files {
        padding-top: 6px;

        &.display-image-descriptions {
          .response-media-file-container {
            width: 100%;

            .response-media-file {
              width: 100px;
            }
          }
        }

        &:not(.display-image-descriptions) {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: flex-start;

          .response-media-file-container {
            width: 100px;
            margin-right: 14px;

            .response-media-file {
              width: 100%;
            }
          }
        }
      }
      .response-media-file-container {
        display: flex;

        ::v-deep img {
          display: block;
          width: 100%;
        }

        .response-media-file {
          margin-right: 18px;
          flex-shrink: 0;
        }
        .response-media-file-description {
          font-size: 14px;
          line-height: 20px;
          color: #434343;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
