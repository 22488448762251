<template>
  <div class="response-option-rating-container">
    <div class="rating-description">
      {{ option.description }}
    </div>
    <div class="rating-value">
      <ul class="list">
        <li
          v-for="(value, index) in [1, 2, 3, 4, 5]"
          :key="index"
          :class="{'active': value <= grade}"
          class="value"
          :data="index"
        >
          <div
            class="icon"
            :class="icon"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InteractionsOptionRating',

  props: {
    option: {
      type: Object,
      default: null
    },
    grade: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: 'star'
    }
  }
}
</script>

<style lang="scss" scoped>
.response-option-rating-container {
  display: flex;
  margin-bottom: 5px;

  .rating-description {
    text-align: left;
    width: 100%;
  }

  .rating-value {
    width: 150px;
  }
}
.rating {
  display: flex;
  align-items: center;
  flex-direction: column;

  .list {
    padding: 0;
    margin: 0;

    .value {
      display: inline-block;
      font-size: 15px;
      cursor: pointer;
      margin: 0px 0px;

      .icon {
        width: 15px;
        height: 15px;
        margin: 0px 2px;
        background-size: contain !important;

        &.star {
          background: url('../../assets/question/icon-question-rating-star.svg') center center no-repeat;
        }

        &.radio_button {
          background: url('../../assets/question/icon-question-rating-radio_button.svg') center center no-repeat;
        }

        &.like {
          background: url('../../assets/question/icon-question-rating-like.svg') center center no-repeat;
        }

        &.heart {
          background: url('../../assets/question/icon-question-rating-heart.svg') center center no-repeat;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0px;
      }

      &.active {
        .icon {
          &.star {
            background: url('../../assets/question/icon-question-rating-star-active.svg') center center no-repeat;
          }

          &.radio_button {
            background:
              url('../../assets/question/icon-question-rating-radio_button-active.svg') center center no-repeat;
          }

          &.like {
            background: url('../../assets/question/icon-question-rating-like-active.svg') center center no-repeat;
          }

          &.heart {
            background: url('../../assets/question/icon-question-rating-heart-active.svg') center center no-repeat;
          }
        }
      }

      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}
</style>
