<template>
  <router-link
    v-slot="{ href, navigate }"
    :to="link"
    class="blue-action-button"
  >
    <a
      :href="href"
      @click="navigate"
    >
      <comment-icon />
      <slot />
    </a>
  </router-link>
</template>

<script>
import CommentIcon from './icons/CommentIcon.vue'

export default {
  name: 'BlueActionButton',
  components: {
    CommentIcon
  },
  props: {
    link: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.blue-action-button {
  font-size: 14px;
  color: #0a6cff;
  background: #e3edfb;
  padding: 0 14px 0 6px;
  height: 27px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: $buttonBackgroundTransition;

  svg {
    display: block;
    width: 14px;
    margin-right: 9px;
  }

  &:hover {
    background: #0a6cff;
    color: #fff;
  }
}
</style>
