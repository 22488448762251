<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable max-len -->
    <path
      d="M13.9997 6.61112C14.0024 7.63768 13.7626 8.65036 13.2997 9.56667C12.751 10.6647 11.9073 11.5883 10.8633 12.2339C9.8193 12.8795 8.61615 13.2217 7.38863 13.2222C6.36207 13.2249 5.34939 12.9851 4.43308 12.5222L-0.000244141 14L1.47753 9.56667C1.0147 8.65036 0.774856 7.63768 0.777532 6.61112C0.778007 5.3836 1.12023 4.18046 1.76587 3.13644C2.41151 2.09243 3.33506 1.24879 4.43308 0.700021C5.34939 0.237191 6.36207 -0.00265437 7.38863 2.21574e-05H7.77752C9.39868 0.0894601 10.9299 0.773724 12.078 1.9218C13.226 3.06987 13.9103 4.60108 13.9997 6.22223V6.61112Z"
      fill="currentColor"
    />
    <!-- eslint-enable -->
  </svg>
</template>

<script>
export default {
  name: 'CommentIcon'
}
</script>
