<template>
  <div class="response-option-container">
    <media
      v-if="hasMedia"
      :model="option"
      class="option-media-file"
    />
    <div class="option-description">
      {{ option.description }}
    </div>
  </div>
</template>

<script>
import Media from '../Media.vue'

export default {
  name: 'InteractionsOption',

  components: {
    Media
  },

  props: {
    option: {
      type: Object,
      default: null
    }
  },

  computed: {
    hasMedia() {
      return this.option.mediaFile != null
    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.response-option-container {
  display: flex;

  .option-media-file {
    margin-right: 18px;
    width: 80px;

    .media-container {
      margin-bottom: 0px;
    }
  }

  .option-description {
    text-align: left;
    font-size: 16px;
    color: #000000;
  }
}
</style>

<style lang="scss">
.response-option-container {
  .option-media-file {
    .media-container {
      margin-bottom: 0px;
    }
  }
}
</style>
