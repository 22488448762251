<template>
  <div class="response-option-nps-container">
    <div class="nps-score">
      {{ option.description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InteractionsOptionNPS',

  props: {
    option: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.response-option-nps-container {
  display: flex;
  padding-top: 10px;

  .nps-score {
    width: 48px;
    height: 48px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;

    background: rgba(196, 196, 196, 0.1);
    border: 0.995399px solid rgba(184, 184, 184, 0.6);
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    color: $blue;
  }

  .nps-details {
    padding: 14px 12px;
    font-size: 14px;
    color: #434343;
  }
}
</style>
