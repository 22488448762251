import { render, staticRenderFns } from "./OptionRating.vue?vue&type=template&id=78dd5d70&scoped=true&"
import script from "./OptionRating.vue?vue&type=script&lang=js&"
export * from "./OptionRating.vue?vue&type=script&lang=js&"
import style0 from "./OptionRating.vue?vue&type=style&index=0&id=78dd5d70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78dd5d70",
  null
  
)

export default component.exports